import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
html,
body {
  margin: 0;
  height: 100%;
  background-color: #fafafa;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: 'cv02', 'cv03', 'cv04', 'cv09', 'cv11';
}

* {
  box-sizing: border-box;
  outline-color: #1967d2;
}

#___gatsby,
#gatsby-focus-wrapper {
  height: 100%;
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  text-decoration: none;
}

p,
li,
a,
button,
label,
input,
select {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Unna', Georgia, 'Times New Roman', Times, serif;
  font-weight: 500;
  color: #171717;
}

h2 {
  margin: 0 0 1.5rem;
  font-size: 2rem;
  line-height: 1.25;
}

p {
  margin: 0 0 1.5rem;
  font-size: 1rem;
  color: #525252;
  line-height: 1.65;
}

button {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  display: flex;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  cursor: pointer;
}

img {
  width: 100%;
}

select {
  padding: 0.6875rem 2.875rem 0.75rem 1rem;
  appearance: none;
  font-size: 0.875rem;
  color: #36383e;
  color-adjust: exact;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-size: 1.375em 1.375em;
  background-repeat: no-repeat;
  border: 1px solid #dddde2;
  border-radius: 0.375rem;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  cursor: pointer;
  
  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-color: #1967d2;
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, #1967d2 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }
}



.sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}
`;
